<!--  -->
<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="handleBox flex flex-jc-sb flex-ai-c">
        <el-page-header @back="goBack" content="统计概况-订单"></el-page-header>
        <el-select v-model="select.order_type" placeholder="类型" size="small" style="margin-left:auto" @change="curr=1;getList()">
            <el-option label="订单" value="1"></el-option>
            <el-option label="退单" value="2"></el-option>
        </el-select>
        <el-button type="primary" size="small" style="margin-left:20px">订单总金额：{{totalAmount}}元</el-button>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( 100% - 175px )">
        <el-table ref="table" :data="list" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column label="订单" min-width="270">
                <template slot-scope="scope">
                    <div class="order-summary">
                        <i class="el-icon-s-platform"></i>
                        <span class="cs-mr">订单号：{{scope.row.order_sn}}</span>
                        <span>创建日期：{{scope.row.create_time}}</span>
                    </div>

                    <div v-for="(goods, index) in scope.row.orderDetails" :key="index" class="flex" style="margin-bottom:10px">
                        <el-image  style="width: 70px; height: 70px;" :src="getImgUrl(goods.product_pic)" :preview-src-list="getImgUrl(goods.product_pic,'arr')" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline" style="font-size:70px"></i>
                            </div>
                        </el-image>
                        <div class="goods-info order-text" style="margin-left:15px">
                            <p>{{goods.product_name}}</p>
                            <p class="son">价格：{{goods.price }} x {{goods.number}}</p>
                            <p class="son">小计：{{goods.subtotal }}</p>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column v-if="isAdmin" label="所属站点" prop="site.name" show-overflow-tooltip></el-table-column>
            <el-table-column prop="sales.name" label="业务员" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column label="订单金额(元)">
                <template slot-scope="scope">
                    <div class="order-text">
                        <p class="son">应付款：<span style="color:#F56C6C">{{scope.row.pay_amount}}</span></p>
                        <p class="son">实付款：{{scope.row.amount}}</p>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="买家" show-overflow-tooltip>
                <template slot-scope="scope">
                    <div class="order-text" v-if="scope.row.user">
                        <p class="son ellipsis">客户名称：{{scope.row.user.name}}</p>
                        <p class="son">联系人：{{scope.row.user.contacts}}</p>
                        <p class="son">手机：{{scope.row.user.mobile}}</p>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="paging">
            <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
        </div>
    </div>

</div>
</template>

<script>
export default {
    data: function() {
        return {
            searchValue: '', //导入input值
            select: {
                order_type:'1'
            },
            list: [],
            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
            siteList:[],
            yewu:[],
            isAdmin:false,
            totalAmount:0
        }
    },
    mounted: function() {
        this.getList();
        if(sessionStorage.getItem('bureau_id')){
            this.isAdmin=false;
        }else{
            this.isAdmin=true;
        }
        // this.getAdmin()
    },
    methods: {
        //返回上一页
        goBack() {
            this.$router.go(-1)
        },
        //站点
        getAdmin: function() {
            this.http.post('/sales.site/getList',{
                curr:1,
                row:99999
            },false).then(re => {
                this.siteList = re.data.list;
            })
            this.http.post('/admin.admin/getSalesList',{},false).then(re=>{
                this.yewu = re.data
            })
        },
        // 图片转换
        getImgUrl(item, type) {
            var url
            if (type == 'arr') {
                url = [this.domain + item];
            } else {
                url = this.domain + item;
            }
            return url;
        },
        getList: function() {
            this.http.post('/admin.statistics/getOrderList', {
                curr: this.curr,
                row: this.row,
                type:this.$route.query.type,
                date:this.$route.query.date,
                order_type:this.select.order_type
            }).then(re => {
                this.list = re.data.list;
                this.curr = re.data.curr;
                this.pages = re.data.pages;
                this.count = re.data.count;
                this.totalAmount = re.data.amount
                this.$nextTick(() => {
                    this.$refs.table.doLayout()
                })
            })
        },
        handleDel(id){
            this.$confirm('此操作将永久删除该条信息, 是否继续', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then( () => {
               this.http.post('/admin.order/delete',{
                   id:id
               }).then(()=>{
                    this.getList();
                    this.$message({
                        message: '成功删除',
                        type: 'success'
                    })
               })
            })
        },
        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
<style media="screen" lang="less">
.order-summary {
    margin-bottom: 10px;
    color: #b3b6bb;
    .cs-mr {
        margin-left: 10px;
        margin-right: 30px;
    }
}
.son {
    color: #909399;
}
</style>
